<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-model="showModal"
    :click-to-close="false"
  >
    <!-- layer : 텍스트 입력 -->
    <section id="modalResultText" class="layer_dialog">
      <div class="clfix mb20">
        <h2 class="fl_l">텍스트 입력</h2>
      </div>

      <div class="textarea">
        <QuillEditor
          id="textarea"
          ref="textarea"
          theme="snow"
          :toolbar="[
            {
              header: [1, 2, 3, 4, 5, 6, false],
            },
            'bold',
            'italic',
            'underline',
            { color: [] },
          ]"
          v-model:content="text"
          contentType="html"
          placeholder="내용을 입력해주세요."
          class="customEdiorClass"
          @editorChange="editorChangeCall($event)"
        />
      </div>

      <div class="btns">
        <ul>
          <li>
            <button
              type="button"
              @click="close"
              class="btn btn_large btn_secondary"
            >
              <span>닫기</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              @click="setResult"
              class="btn btn_large btn_primary"
            >
              <span>저장</span>
            </button>
          </li>
        </ul>
      </div>
    </section>
    <!-- //layer : 텍스트 입력 -->
  </vue-final-modal>
</template>

<script>
import { mapState } from 'vuex';
import common from '@/components/mixin/index.js';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  mixins: [common],
  inheritAttrs: false,
  data: () => ({
    showModal: true,
    text: '',
    color: '#000000',
    font_size: '16px',
    font_bold: 'normal',
  }),
  components: {
    QuillEditor,
  },
  props: ['item', 'type', 'result_desktop_items', 'result_mobile_items'],
  created: async function () {
    this.init();
  },
  computed: {},
  mounted() {
    const buttons = document.querySelectorAll('#btn_color');
    for (const button of buttons) {
      button.addEventListener('blur', this.setColor);
    }

    this.$nextTick(function () {
      $('#btn_color').minicolors({
        animationEasing: 'swing',
        keywords: '#',

        change: (hex, opacity) => {
          this.color = hex;
        },
      });

      $('#btn_color').minicolors('value', this.color);
    });
  },
  methods: {
    init: function () {
      if (this.item !== null) {
        this.text = this.item.text;
        this.color = this.item.color;
        this.font_size = this.item.font_size || '16px';
        this.font_bold = this.item.font_bold || 'normal';
      }
    },
    setResult: function () {
      if (this.text === '') {
        alert('텍스트를 입력해주세요.');
        return false;
      }

      const params = {
        type: 'text',
        text: this.text,
        color: this.color,
        font_size: this.font_size,
        font_bold: this.font_bold,
      };

      if (this.item !== null) {
        if (this.type === 'desktop') {
          this.result_desktop_items.splice(this.item.index, 1, params);
        } else {
          this.result_mobile_items.splice(this.item.index, 1, params);
        }
      } else {
        if (this.type === 'desktop') {
          this.result_desktop_items.push(params);
        } else {
          this.result_mobile_items.push(params);
        }
      }

      this.$emit('updateList', 'desktop', this.result_desktop_items);
      this.$emit('updateList', 'mobile', this.result_mobile_items);

      this.showModal = false;
    },
    setColor: function (e) {
      const key = e.target.id;
      const hex = e.target.value;

      this.color = hex;
    },
    setFontBold: function () {
      if (this.font_bold !== 'bold') {
        this.font_bold = 'bold';
      } else {
        this.font_bold = 'normal';
      }
    },
    inputText: function (e) {
      let value = this.replaceHtml(e.target.value);
      if (e.target.id == 'textarea') {
        this.text = value;
      }
    },
    editorChangeCall: function () {
      let ref = 'textarea';
      let text = this.$refs[ref].getText();
      text = this.replaceHtml(text);
      if (text.length <= 1) {
        this.$refs[ref].setHTML('');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
textarea {
  resize: none;
}
</style>

<style>
/*
placeholder 폰트 기울임으로 인하여 추가
*/
.layer_dialog .ql-editor.ql-blank::before {
  font-style: normal !important;
}
</style>
